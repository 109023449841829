<template>
  <div class="main">
    <div class="text-box">
      <div class="head">一、平台药豆</div>
      <div class="content">用户可以通过注册、签到、下单、参与活动等方式来获取药豆。药豆可在平台药豆商城兑换各类权益、参与各种药豆活动等，具体以活动页面展示为准。</div>
    </div>
    <div class="text-box">
      <div class="head">二、药豆有效期</div>
      <div class="content">用户获得的药豆有效期为自获得当月起的12个自然月，有效期内未使用的药豆到期将自动作废。</div>
      <div class="content msg">温馨提示：</div>
      <div class="content">1、用户日常使用的药豆，优先使用即将过期的药豆；</div>
      <div class="content">2、用户可在“我的”-“药豆详情”里查看药豆数据及过期药豆；</div>
      <div class="content">3、已使用的药豆若发生退还，药豆有效期不变，仍以该笔药豆原获取时间计算有效期。</div>
    </div>
    <div class="text-box">
      <div class="head">三、药豆获取方式</div>
      <div class="content sub-title">1、注册成功</div>
      <div class="content">用户在药百万注册并实名认证成功后，可以获得5药豆。</div>
      <div class="content sub-title">2、每日签到</div>
      <div class="content">用户在药百万移动端“我的”-“签到”页面进行签到，首日可领1个药豆，连续签到每日可递增1个药豆，连续签到每日可领取药豆上限为7个药豆；若签到中断则重新计算。</div>
      <div class="content sub-title">3、下单交易</div>
      <div class="content">用户使用平台商城下单交易，确认交易成功后有资格获得药豆。具体以“我的”-“药豆详情”-“药豆明细”页面展示为准。累积说明：单笔实付每10元可获取1个药豆。</div>
      <div class="content msg">温馨提示：</div>
      <div class="content">当交易发生退款时，全部退款或部分退款的情况下，该笔交易药豆未领取将失效、已领取药豆将被扣除。</div>
      <div class="content sub-title">4、砸蛋游戏</div>
      <div class="content">用户通过“游戏”页面参加砸蛋活动后，可以获得药豆。</div>
      <div class="content sub-title">5、充值</div>
      <div class="content">用户参与药百万“充值”活动，可以获得相应药豆，具体以活动页面展示为准。</div>
      <div class="content sub-title">6、如遇系统繁忙、交易异常等情况，药豆将延迟发放。</div>
    </div>
    <div class="text-box">
      <div class="head">四、药豆兑换</div>
      <div class="content">用户积攒的药豆可以在药豆商城兑换不同的药豆好礼，包括优惠券、视频、药品及实物福利等，详细福利请以当前药豆商城页面为准。兑换药豆后，可在“药豆商城”-“兑换记录”里查询订单记录。
      </div>
    </div>
    <div class="text-box">
      <div class="head">五、其他说明</div>
      <div class="content sub-title">
        1、如用户存在违规刷药豆行为(包括单不限于虚假交易、恶意退款、拆单、套现)，我们有权取消用户获得药豆的资格，已领取的药豆将被扣回。
      </div>
      <div class="content sub-title">
        2、为了进一步改善用户体验，我们将不时更新药豆服务的内容，药豆规则也可能会随之更新，更新内容将于公布之日起生效。我们会以公告、客户端通知或短信等方式向您进行提示。您也可以随时在本页面查阅药豆规则的最新版本。
      </div>
      <div class="content sub-title">
        3、如在活动中心的使用过程中，有任何使用或体验问题，可以联系在线客服进行反馈。
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "IntegralRule",
    components: {
    },
    data() {
      return {};
    },
    created() {},
    methods: {},
    //   注册方法
    mounted() {}
  };

</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .main {
    max-height:300px;
    overflow-y: scroll;
    padding:0px 20px;
    box-sizing: border-box;

    .title {
      font-size:15px;
      height:24px;
      line-height:21px;
      font-weight: 500;
      color: #333;
    }

    .text-box {
      margin-top:12px;
      text-align: left;
      overflow: auto;
      height: auto;

      .head {
        margin-bottom: 10px;
        font-size:14px;
        height:20px;
        line-height:20px;
        font-weight: 600;
        color: #333;
      }

      .content {
        font-size:13px;
        line-height: 18px;
        color: #666666;
        text-indent:20px;
      }

      .msg {
        margin:6px 0;
      }

      .sub-title {
        margin:4px 0;
      }
    }

  }

</style>
